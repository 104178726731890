<template>
	<div class="time">
		<Icon name="clock-outline" class="gray" :width="iconWidth" />
		<span>{{ formatTime(time) }}</span>
	</div>
</template>

<script>
import { formatTime } from '@/utils/utils'
import Icon from './Icon.vue'

export default {
	components: { Icon },

	props: {
		time: {
			type: Number,
			required: true,
		},
		iconWidth: {
			type: String,
			default: '18',
		},
	},

	methods: {
		formatTime,
	},
}
</script>

<style lang="scss" scoped>
.time {
	align-items: center;
	display: flex;

	::v-deep .icon i {
		margin-right: 6px;
	}

	span {
		font-weight: 600;
		font-variant-numeric: tabular-nums;
	}
}
</style>
